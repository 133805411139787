import React from "react"
import {
  CreditCardInput,
  SquarePaymentsForm,
  AchPay,
} from "react-square-web-payments-sdk"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as axios from "axios"

export default function SquareForm({ selectedPackage, fields }) {
  const appId = process.env.REACT_APP_SQUARE_ID
  const locId = process.env.REACT_APP_SQUARE_LID

  const [persons, setPersons] = React.useState(1)

  const handleChange = e => {
    console.log("persons", e.target.value)
    if (
      selectedPackage?.packageSlots &&
      e.target.value > selectedPackage.packageSlots
    ) {
      return setPersons(selectedPackage.packageSlots)
    }
    setPersons(e.target.value)
  }
  const givenName = `${fields?.firstName} ${fields?.lastName}`

  return (
    <>
      {selectedPackage?.packageSlots && selectedPackage.packageSlots > 1 && (
        <div className="input-with-label text-left form-group ">
          <p>How Many people are you paying for</p>
          <input
            style={{
              width: "100%",
              border: "none",
              height: "50px",
              paddingLeft: "20px",
              marginBottom: "24px",
            }}
            className="col-md-6"
            type="text"
            name="persons"
            placeholder={`Max: ${selectedPackage.packageSlots} people`}
            value={persons}
            min="1"
            max={selectedPackage.packageSlots}
            id="persons"
            onChange={handleChange}
          />
        </div>
      )}
      <ToastContainer />
      <SquarePaymentsForm
        locationId={locId}
        applicationId={appId}
        cardTokenizeResponseReceived={async (token, buyer) => {
          const amount = selectedPackage.price * 100 * persons
          const body = {
            locationId: locId,
            sourceId: token.token,
            amount,
            numberofPersons: persons,
            form: fields,
          }
          try {
            const response = axios
              .post("/.netlify/functions/collect", {
                ...body,
              })
              .catch(function (error) {
                if (error.response) {
                  console.log("response error")
                  // Request made and server responded
                  if (error.response.data.status === 501) {
                    console.log(error.response.data)
                    toast.error(error.response.data.message)
                  }
                } else if (error.request) {
                  toast.error(
                    "An error occured in payment. Check your Internet connection."
                  )
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message)
                  toast.error("An error occured in payment.")
                }
              })

            toast.success("Suuceessful! Check your email")
            console.log("response", response)
          } catch (e) {
            console.log("err", e)
            toast.error("An error occured in payment.")
          }
        }}
      >
        <CreditCardInput />
        <AchPay accountHolderName={givenName} />
      </SquarePaymentsForm>
    </>
  )
}
